import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { ContactService } from "../api";

export const actionCreateContact = createAsyncThunk(
  "contact/create-contact",
  async (data, { rejectWithValue }) => {
    try {
      const res = await ContactService.doCreateContact(data);
      if (res.data.status) {
        toast.success(res.data.message);
        return res.data;
      } else {
        toast.error(res.data.message);
        return rejectWithValue(res.data);
      }
    } catch (error) {
      const message = error.response?.data?.message || error.message;
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const actionGetListType = createAsyncThunk(
  "contact/get-list-type",
  async () => {
    try {
      const res = await ContactService.getListType();

      if (res.data.status) {
        return res.data;
      } else {
        return res.data;
      }
    } catch (error) {
      const message = error.response?.data?.message || error.message;
      return message;
    }
  }
);

export const actionDoFormList = createAsyncThunk(
  "contact/form-list",
  async (data) => {
    try {
      const res = await ContactService.doFormList(data);
      if (res.data.status) {
        toast.success(res.data.message);
        return res.data;
      } else {
        toast.error(res.data.message);
        return res.data;
      }
    } catch (error) {
      const message = error.response?.data?.message || error.message;
      toast.error(message);
      return message;
    }
  }
);

const ContactSlice = createSlice({
  name: "contact",
  initialState: {
    createContact: {
      loading: false,
      data: null,
      error: "",
    },
    formList: {
      loading: false,
      data: null,
      error: "",
    },
    listType: {
      loading: false,
      data: null,
      error: "",
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // create Contact
      .addCase(actionCreateContact.pending, (state) => {
        state.createContact.loading = true;
      })
      .addCase(actionCreateContact.fulfilled, (state, action) => {
        state.createContact.loading = false;
        state.createContact.data = action.payload;
      })
      .addCase(actionCreateContact.rejected, (state, action) => {
        state.createContact.loading = false;
        state.createContact.data = action.payload;
        state.createContact.error =
          action.error.message || "Something went wrong";
      })
      // formList
      .addCase(actionDoFormList.pending, (state) => {
        state.formList.loading = true;
      })
      .addCase(actionDoFormList.fulfilled, (state, action) => {
        state.formList.loading = false;
        state.formList.data = action.payload;
      })
      .addCase(actionDoFormList.rejected, (state, action) => {
        state.formList.loading = false;
        state.formList.data = action.payload;
        state.formList.error = action.error.message || "Something went wrong";
      })
      // list Type
      .addCase(actionGetListType.pending, (state) => {
        state.listType.loading = true;
      })
      .addCase(actionGetListType.fulfilled, (state, action) => {
        state.listType.loading = false;
        state.listType.data = action.payload;
      })
      .addCase(actionGetListType.rejected, (state, action) => {
        state.listType.loading = false;
        state.listType.data = action.payload;
        state.listType.error = action.error.message || "Something went wrong";
      });
  },
});

export default ContactSlice.reducer;
export const { actions, reducer } = ContactSlice;
