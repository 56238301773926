import axios from "axios";
export const NEXT_BASE_URL = "https://apiairdrop.pionechain.com/";
// export const NEXT_BASE_URL = "http://192.168.1.4:7022/";
const API = axios.create({
  baseURL: NEXT_BASE_URL,
});

export const ContactService = {
  doCreateContact: (data) =>
    API.post("api/v1/common/master/contact-send", data),
  getListType: () => API.get("api/v1/common/contact_d_app/list_type"),
  doFormList: (data) =>
    API.post("api/v1/common/contact_d_app/send_d_app", data),
};
